body {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App {
  text-align: center;
}

.card {
  width:244px;
  height:340px;
  position:relative;
}

.foil:before{
  content:"";
  display:block;
  position:absolute;
  background: rgba(255,255,255, 0.1);
}

.card-stack {
  position:relative;
  display:inline-block;
  text-align:left;
}

.card-stack span {
  position:absolute;
}

.player-pool{
  display:inline-block;
  min-width:180px;
}

.player-pack{
  display:inline-block;
  min-width:420px;
}

.player-box {
  padding: 10px;
  margin-top:5px;
  border: 2px solid black;
}

.top-bar {
  color:white;
  font-size: calc(2px + 2vmin);
  background-color: black;
  padding-right: 15px;
  text-align: right;
  padding-top: 2px;
  padding-bottom: 2px;
}

.top-bar a {
  text-transform: uppercase;
  color:white;
  font-weight: bold;
  text-decoration: none;
}

.top-bar span {
  height: 100%;
  vertical-align: top;
}

.avatar-img {
  margin-top:5px;
  height: calc(2px + 2vmin);
  border-radius: 50%;
}
